import { SxStyleProp } from 'theme-ui';

export const SuperCheese: SxStyleProp = {
  flexDirection: ['column-reverse', 'row'],
  alignItems: 'stretch',
  py: '24px',
  backgroundColor: 'primary',
};

export const Pizza: SxStyleProp = {
  width: ['100%', '68%'],
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  mt: ['24px', 0],
};

export const Image: SxStyleProp = {
  width: '100%',
  height: 'auto',
  minHeight: '1px',
  verticalAlign: 0,
};

export const Content: SxStyleProp = {
  position: 'relative',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: ['100%', '32%'],
  alignSelf: ['center', 'center', 'inherit'],
  pl: ['32px', 0],
};

export const NewText: SxStyleProp = {
  lineHeight: '0.8em',
  fontSize: [25, 25, 33],
  textTransform: 'uppercase',
};

const Ticks: SxStyleProp = {
  width: ['16px', '16px', '20px'],
};

export const Accent: SxStyleProp = {
  ...Ticks,
  ml: ['-20px', '-20px', '-24px'],
  mr: '4px',
};

export const AccentReverse: SxStyleProp = {
  ...Ticks,
  ml: '4px',
  transform: 'scaleX(-1)',
};

export const LtoText: SxStyleProp = {
  fontSize: [9, 9, 12],
  lineHeight: 'initial',
};

export const LtoPriceText: SxStyleProp = {
  color: 'white',
  display: 'flex',
  justifyContent: 'flex-start',
  lineHeight: '0.8em',
  fontSize: [64, 64, 90],
  m: 0,
};

export const CurrencySymbol: SxStyleProp = {
  width: ['22px', '22px', '32px'],
  height: 'auto',
  alignSelf: 'flex-start',
  mt: 3,
};

export const Script: SxStyleProp = {
  fontSize: [13, 13, 18],
  mb: '-12px',
  display: 'block',
};

export const Registered: SxStyleProp = {
  fontSize: [5, 5, 6],
  display: 'inline-block',
  verticalAlign: 'top',
};

export const LtoTitleText: SxStyleProp = {
  fontSize: [26, 26, 40],
  lineHeight: 0.8,
  color: 'white',
  textTransform: 'uppercase',
};
