import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Box from '@lce/slice_v2/Layout/Box';
import Text from '@lce/slice_v2/Elements/Text';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';
import Script from '@lce/intl-ui/src/Elements/Script';

import Accent from './assets/accent.png';
import SuperCheeseImage from './assets/super-cheese-image.png';
import * as Styled from './SuperCheese.style';

const currencySpearWhite = 'https://www.datocms-assets.com/24611/1588170919-currencyspearsymbolwhite.svg';

const SuperCheese = () => (
  <Flex sx={Styled.SuperCheese}>
    <Flex sx={Styled.Pizza}>
      <Image alt="" src={SuperCheeseImage} sx={Styled.Image} />
    </Flex>
    <Flex sx={Styled.Content}>
      <Heading as="h2" sx={Styled.NewText}>
        <Box>
          <Image alt="" src={Accent} sx={Styled.Accent} />
          nueva
          <Image alt="" src={Accent} sx={Styled.AccentReverse} />
        </Box>
        <Text sx={Styled.LtoText}>por tiempo limitado</Text>
      </Heading>
      <Heading as="h2" sx={Styled.LtoPriceText}>
        <Image src={currencySpearWhite} sx={Styled.CurrencySymbol} />
        499
      </Heading>
      <Box>
        <Script sx={{ ...Styled.Script, display: 'inline-block' }}>Hot-N-Ready</Script><Text sx={Styled.Registered}>®</Text>
        <Heading as="h1" sx={Styled.LtoTitleText}>Super Cheese</Heading>
      </Box>
    </Flex>
  </Flex>
);

export default SuperCheese;
